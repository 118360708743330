<template>
    <Suspense>
        <div class="relative">
            <InputField
                type="text"
                v-model="searchText"
                icon="search-md"
                placeholder="Search..."
                @focus="isFocused=true"
                @blur="isFocused=false"
            />
            <div v-show="isVisible" class="options shadow-md rounded-lg border-[1px] max-h-[350px] overflow-scroll bg-[#FFF] w-[400px] left-1/2 -translate-x-1/2 no-scrollbar absolute mt-[4px] top-[100%] divide-y z-[100]" @mouseenter="isMouseOver = true" @mouseleave="isMouseOver = false">
                <a
                    v-for="data in profiles" :key="data"
                    class="p-[8px] grid grid-cols-[52px_1fr] gap-[8px] hover:bg-[#F5FAFF] group"
                    :href="`/profiles/${data.id}`"   
                >
                    <div class="w-[52px] h-[52px] rounded-full lazy-container">
                        <img class="lazy" :src="data.profile_picture.url" alt="" onload="this.classList.add('loaded')" loading="lazy"/>
                        <img class="fallback" src="@assets/images/placeholders/profile-picture.svg" alt="" />
                    </div>
                    <div class="flex flex-col grow">
                        <span class="text-sm font-medium text-[#0A0D14] group-hover:text-[#375DFB]">{{ data.full_name }}</span>
                        <span v-if="data.headline" class="text-xs ellipsis overflow-hidden text-[#0A0D14] group-hover:text-[#375DFB]">{{ data.headline }}</span>
                        <span v-if="data.current_job" class="text-xs text-[#525866] group-hover:text-[#375DFB]">
                            {{ data.current_job.position }} @{{ data.current_job.company }}
                        </span>
                    </div>
                </a>
                <div v-if="profiles.length == 0" class="p-[16px] gap-[8px]">
                    No results found...
                </div>
            </div>
        </div>
        <template #fallback>
            <div class="w-[206px] bg-[#E5E7EB] h-[40px] rounded-[8px] animate-pulse"></div>
        </template>
    </Suspense>
</template>
<script>
import Client from "@/utils/client";

export default {
    name: "HeaderSearchApp",
    data: () => ({
        searchText: "",
        profiles: [],
        saveTimeout: null,
        saveDelay: 200,
        isMouseOver: false,
        isFocused: false
    }),
    computed: {
        isVisible() {
            return this.searchText.length > 0 && (this.isMouseOver || this.isFocused) ;
        }
    },
    watch: {
        isVisible(newValue) {
            if (!newValue) {
                clearTimeout(this.saveTimeout);
                this.profiles = [];
            }
        },
        searchText() {
            if (!this.isVisible) return null
            clearTimeout(this.saveTimeout);
            this.saveTimeout = setTimeout(() => { this.search() }, this.saveDelay);
        }
    },
    methods: {
        close() {
            if (!this.isMouseOver) {
                this.$refs.input.removeAttribute("open");
                this.isMouseOver = false;
            }
        },
        async search() {
            const response = await Client.post('/dashboard/niche_search/', {
                terms: this.searchText,
                service_reach: "All",
                limit: 5
            });
        
            this.profiles = await response.json();
        }
    }
}
</script>